@import url(https://fonts.googleapis.com/css?family=Quicksand&display=swap);
@import url(https://fonts.googleapis.com/css?family=Aclonica&display=swap);
@import url(https://fonts.googleapis.com/css?family=Comfortaa:400&display=swap);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  height:710px;
  display:grid;
  grid-template-columns: 1fr 4fr;
  font-family: 'Quicksand',Arial, sans-serif;
  line-height: 1.3em;
  letter-spacing: 0.04em;
}
.side{
width:300px;
}
.main{
  overflow: scroll;
  overflow-x: hidden;
}

p{
  color: rgba(0, 0, 0, 0.7);
 font-weight: normal;
}


/*//////////////// Media Query/////////////////*/


@media (max-width:992px){
  .App{
    height:100%;
    display: flex;
    flex-direction: column;
  }
  h2{
    font-weight: bold;
  }
  p{
    font-weight: bolder;
  }
  .main{
    margin-top: -40px;
    overflow-y: hidden;
  }
}

@media (max-width:769px){
  .App{
     display: flex;
    flex-direction: column;

  }
  .main{
    overflow: auto;
    overflow-x: hidden;
  }

}

.sidebar{
    height:100vh;
    /* margin-top: -50px; */
    width:100%;
    background-color:#f2f3f7;
    overflow-y: scroll;
     text-align: center;
}
.sidebar > h1 {
    padding-top: 20px;
    text-align: center;
}
.tagtop{
  margin-top:40px
}

.h1_links{
  text-decoration: none;
  font-family: 'Aclonica', sans-serif;
  color:#000;
  font-size:32px;
}
.sidebar > p {
    margin-top: 20px;
    text-align: center;
}

.sidebar-nav{
    margin-left: -30px;
    margin-top: 30px;
    text-align: center;
}
.sidebar-nav-items{
    margin-bottom: 15px;
}
.sidebar>img{
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-top: 10px;
}
.links{
  text-decoration: none;
  /* font-family: 'Quicksand', sans-serif; */
  font-family: 'Comfortaa', cursive;
  color:#000;
  font-size: larger;
  line-height: 1.4rem;
}
.sidebar-nav-icons{
    margin-bottom: 15px;
}

.flip-card-back{
  margin-top:50px;
}
.fa-lg{
  text-decoration: none;
  color:red;
}

.links:hover{
 /* color:rgb(121, 189, 252); */
 color:rgb(121, 189, 252);
 text-decoration: underline;
}
.fas:hover{
  color:rgb(41, 152, 255);
  text-decoration: underline;
 }
 ul{
   list-style: none;
 }
  .h1_links:hover{
  color: #000;
 }
 /*//////////////media ////////////////////*/
 @media (max-width:980px){
  .sidebar{
    width:106vw;
  }
  .h1_links{
    font-size:29px;
  }
 }


@media (max-width:768px){
  .sidebar{
    width:106vw;
  }
  .h1_links{
    font-size:28px;
  }
  .links{
display:none;
  }
  .fas{
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    margin:5px 0px;
  }
 .gmail{
   font-size:16px;  
   font-weight: normal;
 }
}
 
@media (max-width:370px){
  .sidebar{
    width:106vw;
  }
  .h1_links{
    font-size:24px;
  }
  .links{
    font-size: 16px;
  }
  .fas{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    margin:5px 0px;
  }

}
.About_box__2iRF7{
    margin:15px;
    margin-top: 40px;

}
.About_About__1dBX1{
    margin-left:20px;
    padding:20px;
}

.About_br__5D48f{
    margin-top: 20px;
}

.About_head__3DYrU{
    display: inline-block;
    padding:0px 12px;
    color: rgb(85, 85, 85);
    margin-bottom: 20px;
    margin-left:30px;
    letter-spacing: 5px;
    font-size: 11px;
}

.About_heading__1WzB7 {  
    padding:0px 12px;
    font-size: 18px;
    font-weight: 700;
    margin-left:30px;
    text-transform: uppercase;
    letter-spacing: 5px;
    line-height: 1.8;
}
.About_label__3MYou {
    position: relative;
    background: #f2f3f7;
    padding: 1.5em;
    margin-left: 60px;
}
.About_label__3MYou h2 {
    font-size: 20px;
    font-family: "Quicksand", Arial, sans-serif;
    font-weight: 500;
   
}
.About_label__3MYou p {
    margin-left: 200px;
   
}

  
  @-webkit-keyframes About_text__3d2ki{
    from{-webkit-transform: translateX(-30px);transform: translateX(-30px);
        opacity:0.5;
    }
  }

  
  @keyframes About_text__3d2ki{
    from{-webkit-transform: translateX(-30px);transform: translateX(-30px);
        opacity:0.5;
    }
  }
  

  @media (max-width:992px){
    .About_box__2iRF7{
        text-align: center;
    }
}
.Education_box__r7v1f{
    margin:15px;
    margin-top:50px;
}
.Education_head__2SE2U{
    display: inline-block;
    padding:0px 12px;
    color: rgb(85, 85, 85);
    margin-bottom: 20px;
    margin-left:30px;
    letter-spacing: 5px;
    font-size: 11px;
}


.Education_container__235Ac{
    width: 100%;
    margin-left:30px;
    display: block;
    margin-top: 30px;
    border-radius:3px;
    padding:0 42px 0 10px;
    margin-left:30px
    /* background-color: red; */
}
.Education_row__fcmeF:before{
    content: " ";
    display: table;
}
.Education_row__fcmeF:after {
    clear: both;
}
.Education_row_md_12__FBLeR{
    display: flex;
    flex-direction: column;
}
.Education_timeline_centered__1K6q9 {
    z-index: 1;
    position: relative;
    margin-bottom: 30px;
}
.Education_timeline_centered__1K6q9:before {
    content: '';
    position: absolute;
    display: block;
    width: 4px;
    background:#f3f2f7;
    top: 20px;
    z-index: -1;
    bottom: 20px;
    margin-left: 19px;
}
.Education_timeline_entry__44nW5 {
    margin-bottom: 10px;
    clear: both;
}
.Education_timeline_icon__3fqWJ {
    display: block;
    width: 40px;
    font-size:20px;
    height: 40px;
background-color: #f9bf3f;
    border-radius: 50%;
    text-align: center;
    box-shadow: 0 0 0 5px #f2f3f7;
    line-height: 35px;
    float: left;
}
.Education_timeline_icon_2__2jgeY {
    background: #DE1DB4;
}
.Education_timeline_icon_5__1jvkG {
    background: #00ffff;
}

.Education_timeline_icon_4__17c3E{
    background: #ff0040;
}

.Education_timeline_entry_inner__3uKYG {
    position: relative;
    top:0px;
    margin-left: 11.5px;
    
}
.Education_timeline_icon_3__2Rer7 {
    display: block;
    width: 20px;
    font-size:20px;
    height: 20px;
    background: #fff;
    border-radius: 50%;
    text-align: center;
    box-shadow: 0 0 0 5px #f2f3f7;
    line-height: 35px;
    float: left;
}

/* .heading {  
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 5px;
    line-height: 1.8;
} */
.Education_label__WMsfh {
    position: relative;
    background: #f2f3f7;
    padding: 1.5em;
    margin-left: 60px;
}
.Education_label__WMsfh h2 {
    font-size: 20px;
    font-family: "Quicksand", Arial, sans-serif;
    font-weight: 500;
    margin: 0 0 30px 0;
    color:#000;
}
.Education_label__WMsfh h2 span {
    -webkit-opacity: .4;
    -moz-opacity: .4;
    opacity: .4;
    -ms-filter: alpha(opacity=40);
    filter: alpha(opacity=40);
    font-size: 16px;
}

.Education_label__WMsfh p {
    margin-bottom: 1.5em;
    
    /* margin-left: 200px; */
   
}

p>a{
    text-decoration: none;
    color:inherit;
}

a:hover{
color:rgb(121, 189, 252);
text-decoration: none;
}

@-webkit-keyframes Education_text__1DhKt{
    from{-webkit-transform: translateX(-30px);transform: translateX(-30px);
        opacity:0.1;
    }
    /* to{opacity: 1;} */
  }

@keyframes Education_text__1DhKt{
    from{-webkit-transform: translateX(-30px);transform: translateX(-30px);
        opacity:0.1;
    }
    /* to{opacity: 1;} */
  }

  
  @media (max-width:992px){
    .Education_box__r7v1f{
        text-align: center;
    }
}

@media (max-width:440px){
    .Education_container__235Ac{
        padding:0 42px 0 0;
    }
}
.Interest_box__1QXKR{
    margin:15px;
    margin-top: 50px;
    /* box-shadow: 10px 10px 20px rgba(244,241,241,1) ; */
    border-radius:6px;
    /* animation-name: text;
    animation-duration: 7s;
    animation-timing-function: ease-out; */
}
.Interest_Interest__1nrKb {
    /* background-color:rgba(220, 220,220, 0.3); */
    border-radius:3px;
    margin-left:20px;
    margin-top:45px;
    padding:20px;
    display:grid;
    grid-template-columns:repeat(3,1fr);
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
       -moz-column-gap: 20px;
            column-gap: 20px; 
}
.Interest_head__1v98p{
    display: inline-block;
    padding:0px 12px;
    color: rgb(85, 85, 85);
    margin-bottom: 20px;
    margin-left:30px;
    letter-spacing: 5px;
    font-size: 11px;
}

.Interest_heading__XTPzc {  
    padding:0px 12px;
    font-size: 18px;
    font-weight: 700;
    margin-left:30px;
    text-transform: uppercase;
    letter-spacing: 5px;
    line-height: 1.8;
}
div>h3{
        font-size: 16px;
        font-weight: 900;
        text-transform: uppercase;
        margin: 20px 0 30px 0;
}
div>p{
    font-size: 15px;
}
.Interest_web__34oC7{
    height: 100%;
    /* height:180px; */
   border:1px  black;
   border-bottom:2px solid #2c98f0;
   text-align: center;
   padding:1.2rem;
   box-shadow:  0px 0px 56px -8px rgba(0, 0, 0, 0.17) ;
    
}
.Interest_app__bEJ73{
        /* height:180px; */
    height: 100%;
    border:1px  black;
   border-bottom:2px solid #f9bf3f ;
   text-align: center;
   box-shadow: 10px 10px 20px rgba(244,241,241,1) ;
    position: relative;
    top:-20px;
    padding:1.2rem;
}
.Interest_other__2P7kx{
    height: 100%;
    /* height:180px; */
   border:1px  black;
   padding:1.2rem;
   border-bottom:2px solid #2fa499;
   text-align: center;
   box-shadow: 10px 10px 20px rgba(244,241,241,1) ;

}

@-webkit-keyframes Interest_text__1kD-J{
    from{-webkit-transform: translateX(-30px);transform: translateX(-30px);
        opacity:0;
    }
  }

@keyframes Interest_text__1kD-J{
    from{-webkit-transform: translateX(-30px);transform: translateX(-30px);
        opacity:0;
    }
  }

/*///////// Media Query //////////////*/
@media (max-width:1027px){
    
    .Interest_Interest__1nrKb {
        grid-template-columns: 1fr;
    }
    .Interest_web__34oC7{
        margin-bottom:25px
    }
    .Interest_app__bEJ73{
        margin-bottom:25px
    }
    .Interest_other__2P7kx{
        margin-bottom:25px
    }
}


@media (max-width:769px){
    .Interest_box__1QXKR{
        text-align: center;
    }
    .Interest_Interest__1nrKb {
        grid-template-columns: 1fr;
    }
    .Interest_app__bEJ73{
        margin-top:20px;
        height:100%;
         /* position: static; */
     }
     .Interest_other__2P7kx{
        margin-top:20px;
     }
}

@media (max-width:392px){
    .Interest_Interest__1nrKb {
        grid-template-columns: 1fr;
    }
    .Interest_app__bEJ73{
         position: static;
     }
}
.Projects_box__3tT7F{
    margin:15px;
    margin-top: 50px;
    /* box-shadow: 10px 10px 20px rgba(244,241,241,1) ; */
    border-radius:6px;
    /* animation-name: text;
    animation-duration: 7s;
    animation-timing-function: ease-out; */
}
.Projects_Project__3kFS6 {
    /* background-color:rgba(220, 220,220, 0.3); */
    border-radius:3px;
    margin-left:20px;
    margin-top:45px;
    padding:20px;
    display:grid;
    grid-template-columns:repeat(3,1fr);
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
       -moz-column-gap: 20px;
            column-gap: 20px; 
}
.Projects_head__3kWAH{
    display: inline-block;
    padding:0px 12px;
    color: rgb(85, 85, 85);
    margin-bottom: 20px;
    margin-left:30px;
    letter-spacing: 5px;
    font-size: 11px;
}

.Projects_heading__11dNK {  
    padding:0px 12px;
    font-size: 18px;
    font-weight: 700;
    margin-left:30px;
    text-transform: uppercase;
    letter-spacing: 5px;
    line-height: 1.8;
}
div>h3{
        font-size: 16px;
        font-weight: 900;
        text-transform: uppercase;
        margin: 20px 0 30px 0;
}
div>p{
    font-size: 15px;
}
.Projects_web__39t-S{
    height: 100%;
    /* height:180px; */
   border:1px  black;
   border-bottom:2px solid #2c98f0;
   text-align: center;
   padding:1.2rem;
   box-shadow:  0px 0px 56px -8px rgba(0, 0, 0, 0.17) ;
}
.Projects_web__39t-S>img{
    width: 180px;
    height: 180px;
    margin-top: 2px;
    margin-bottom: 2px;
}
.Projects_app__2YuML{
        /* height:180px; */
    height: 100%;
    border:1px  black;
   border-bottom:2px solid #f9bf3f ;
   text-align: center;
   box-shadow: 10px 10px 20px rgba(244,241,241,1) ;
    position: relative;
    top:-20px;
    padding:1.2rem;
}
.Projects_app__2YuML>img{
    width: 180px;
    height: 180px;
    margin-top: 2px;
    margin-bottom: 2px;
}
.Projects_other__1pu09{
    height: 100%;
    /* height:180px; */
   border:1px  black;
   padding:1.2rem;
   border-bottom:2px solid #2fa499;
   text-align: center;
   box-shadow: 10px 10px 20px rgba(244,241,241,1) ;

}

@-webkit-keyframes Projects_text__2NWvc{
    from{-webkit-transform: translateX(-30px);transform: translateX(-30px);
        opacity:0;
    }
  }

@keyframes Projects_text__2NWvc{
    from{-webkit-transform: translateX(-30px);transform: translateX(-30px);
        opacity:0;
    }
  }

/*///////// Media Query //////////////*/
@media (max-width:1027px){
    
    .Projects_Project__3kFS6 {
        grid-template-columns: 1fr;
    }
    .Projects_web__39t-S{
        margin-bottom:25px
    }
    .Projects_app__2YuML{
        margin-bottom:25px
    }
    .Projects_other__1pu09{
        margin-bottom:25px
    }
}


@media (max-width:769px){
    .Projects_box__3tT7F{
        text-align: center;
    }
    .Projects_Project__3kFS6 {
        grid-template-columns: 1fr;
    }
    .Projects_app__2YuML{
        margin-top:20px;
        height:100%;
         /* position: static; */
     }
     .Projects_other__1pu09{
        margin-top:20px;
     }
}

@media (max-width:392px){
    .Projects_Project__3kFS6 {
        grid-template-columns: 1fr;
    }
    .Projects_app__2YuML{
         position: static;
     }
}
.CarouselImages_image__2vh2r{
  height:734px;
}

.CarouselImages_h1__2chs7{
    margin:50px;
    font-size: 2rem;
    font-family: 'Permanent Marker', cursive;
    -webkit-animation-name: CarouselImages_text__1lDBD;
            animation-name: CarouselImages_text__1lDBD;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    position: relative;
    top:-270px;
    left:10px;
    color:rgba(255, 255,255, 0.8)
  }
  
  .CarouselImages_h2__CYEmF{
    margin:50px;
    font-size: 2rem;
    font-family: 'Permanent Marker', cursive;
    -webkit-animation-name: CarouselImages_text__1lDBD;
            animation-name: CarouselImages_text__1lDBD;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    position: relative;
    top:-470px;
    left:-20px;
    text-align: right;
    color:black;
  }

  .CarouselImages_h1__2chs7 a{
    color:rgba(255, 255,255, 0.8);
    width: 170px;
    margin:50px;
    font-size: 1.1rem;
    font-family: 'Permanent Marker', cursive;
    -webkit-animation-name: CarouselImages_text__1lDBD;
            animation-name: CarouselImages_text__1lDBD;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    position: relative;
    top:20px;
    left:-7px;
    color:rgba(255, 255,255, 0.8);
    padding: 11px 24px;
    box-shadow: 0px 10px 30px 0px rgba(21, 10, 82, 0.35);
    border-radius:22px;
    background-image:-webkit-gradient(linear,right top, left top,from(rgba(75,19,79,0.8)) ,to(rgba(201,75,75,0.6)));
    background-image:linear-gradient(to left,rgba(75,19,79,0.8) ,rgba(201,75,75,0.6));
  }
  .CarouselImages_h2__CYEmF a{
    color:rgba(255, 255,255, 0.8);
    width: 170px;
    margin:50px;
    font-size: 1.1rem;
    font-family: 'Permanent Marker', cursive;
    -webkit-animation-name: CarouselImages_text__1lDBD;
            animation-name: CarouselImages_text__1lDBD;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    position: relative;
    top:20px;
    left:-7px;
    color:rgba(255, 255,255, 0.8);
    padding: 11px 24px;
    box-shadow: 0px 10px 30px 0px rgba(21, 10, 82, 0.35);
    border-radius:22px;
    background-image:-webkit-gradient(linear,right top, left top,from(rgba(75,19,79,0.8)) ,to(rgba(201,75,75,0.6)));
    background-image:linear-gradient(to left,rgba(75,19,79,0.8) ,rgba(201,75,75,0.6));
  }

  @-webkit-keyframes CarouselImages_text__1lDBD{
    from{-webkit-transform: translateY(20px);transform: translateY(20px);
    opacity: 0.2;}
    to{opacity: 1;}
  }

  @keyframes CarouselImages_text__1lDBD{
    from{-webkit-transform: translateY(20px);transform: translateY(20px);
    opacity: 0.2;}
    to{opacity: 1;}
  }
  
  @media (max-width:400px){
    .CarouselImages_image__2vh2r{
      height:600px;
    }
    .CarouselImages_h1__2chs7 a{
      width: 120px;
      font-size: 0.8rem;
      margin:20px;
      padding: 9px 18px;
    }
    .CarouselImages_h2__CYEmF a{
      width: 120px;
      font-size: 0.8rem;
      margin:20px;
      padding: 9px 18px;
      position: relative;
    top:20px;
    left:17px;
    }
  }
